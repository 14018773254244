import React from 'react';

import { Image } from 'src/components-v2/Media';
import { Button } from 'src/components/Inputs';
import DefaultTemplate from 'src/templates/default';

// helpers
import { CmsThemes, Locales } from 'src/lib/constants';
import { dangerous_getStaticData } from 'src/lib/getStaticData';
import { Spacer } from 'src/components-v2/Layout';

const img = {
  MOBILE_IMAGE: {
    x1: '?fm=webp&w=338&q=30',
    x2: '?fm=webp&w=676&q=30',
  },
  DESKTOP_IMAGE: {
    x1: '?fm=webp&w=569&q=30',
    x2: '?fm=webp&w=1138&q=30',
  },
  src: 'https://images.ctfassets.net/ss5kfr270og3/25o0SfhdB2OsmuZynyrMhX/686b3952cfcbb84f4fb07744f33f8564/abe-contact-success.png',
};

const ContactThanks = ({ locale = Locales.EN_US }) => {
  const { metaData, heading, subheading, cta } = dangerous_getStaticData({
    locale,
    filePath: 'contact-thanks',
  });

  const cmsTheme = locale === Locales.EN_US ? CmsThemes.WEB3 : CmsThemes.WEB2;

  return (
    <DefaultTemplate {...metaData} locale={locale} cmsTheme={cmsTheme}>
      {cmsTheme === CmsThemes.WEB3 && (
        <Spacer
          height={119}
          sx={{
            height: {
              xs: '104px',
              md: '119px',
            },
          }}
        />
      )}
      <section>
        <div className='grid-container section-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell medium-12 large-5'>
              <h1 className='section-header'>{heading}</h1>
            </div>
          </div>

          <div className='grid-x grid-margin-x contact-thanks-grid'>
            <div className='cell large-5'>
              <h4 className='regular section-subheader'>{subheading}</h4>
              <Button href={cta?.url} className='mb1' secondary fullWidth>
                {cta?.text}
              </Button>
            </div>

            <div className='cell small-7 large-auto'>
              <Image
                isLazy={false}
                className='contact-inline-img'
                src={img.src + img.DESKTOP_IMAGE.x2}
                alt=''
                width='569'
                height='425'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: `${img.src + img.MOBILE_IMAGE.x1}, ${
                      img.src + img.MOBILE_IMAGE.x2
                    } 2x`,
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: `${img.src + img.DESKTOP_IMAGE.x1}, ${
                      img.src + img.DESKTOP_IMAGE.x2
                    } 2x`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </DefaultTemplate>
  );
};

export default ContactThanks;
